/* Mainly just used to house the "initialState() - actions & getters are 
  both found in "bandDashboard" & "influencerDashboard" stores
*/
import type Submission from '~/types/submission'
import type SubmissionStatus from '~/types/submissionStatus'

export const dashboarCommonInitialState = () => ({
  submissions: {} as Record<number, Submission>,
  submissions_by_status: {
    Se: [],
    Wa: [],
    Wc: [],
    Fn: [],
    Ex: [],
  } as Record<SubmissionStatus, number[]>,
  fetched_status: [] as SubmissionStatus[],
  submission_count: 0,
  loading: false,
  status_priority: [] as SubmissionStatus[],
  isFetching: false,
})

export type DashboardCommonState = ReturnType<typeof dashboarCommonInitialState>
